@import "mixins/grid";
$page-top-offset: 10px;
$toolbar-height: 55px;
$left-column-width: 330px;
$main-view-width: 950px;
$search-box-height: 135px;
$search-box-shrink-height: 73px;
$right-column-width: 350px;

.toolbar {
    height: $toolbar-height;
    width: 100%;
    display: flex;
    margin-left: 2px;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid var(--gray-100);
    background-color: white;
    .twin-avatars {
        display: flex;
        img {
            height: 35px;
            border-radius: 100%;
            margin-left: -6px;
            outline: 3px solid white;
            &:first-child {
                margin-left: 0;
                border: none;
            }
        }
    }
    .arin-name {
        font-weight: bold;
    }
}

#left-panel {
    border-radius: 8px;
    padding-top: 10px;
    display: grid;
    grid-template-rows: 55px auto;
    grid-template-columns: 100%;

    .switch-header {
        background-color: white;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .sidebar-sm {
        border-radius: 0 !important;
    }
}

.page-body {
    height: calc(100% - #{$toolbar-height});
    width: 100%;
    overflow-y: auto;
    .search-page-view {
        width: 850px;
        margin: auto;
        height: auto;
    }
}

.error-card {
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        height: 100px;
    }
    ul.tips-list {
        list-style-type: disc;
    }
}

/**

 Css transition classes
 */

.left-switch-node-enter {
    opacity: 0;
}

.left-switch-node-enter-active {
    opacity: 1;
    transition: opacity 50ms;
}

.left-switch-node-exit {
    opacity: 1;
}

.left-switch-node-exit-active {
    opacity: 0;
    transition: opacity 80ms;
}
