$sidebar-width: 350px;
.account-root-page {
    display: grid;
    grid-template-columns: $sidebar-width auto;
}
.account-sidebar {
    ul.account-links {
        // padding-right: 35px;
        li {
            margin-bottom: 1em;
            a.no-page {
                display: flex;
                color: #6e6e80;
                font-size: 14px;
                line-height: 20px;
                text-decoration: none;
                gap: 10px;
                align-items: center;
                padding: 8px 0;
                border-radius: 8px;
            }
        }
        ul.sub-links {
            // padding-left: 28px;
            li {
                margin-bottom: 0.3em;
                font-size: 15px;
            }
        }
    }
}

.accounts-subview {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
/**
 ===== MEMBERS PAGE =========
 */
.accounts-members {
    .account-body {
        .user-card {
            display: grid;
            grid-template-columns: 50% 200px auto;
            padding: 12px 12px;
            border-bottom: 1px solid var(--gray-100);
            .user-info {
                display: flex;
                gap: 15px;
                img {
                    height: 35px;
                    width: 35px;
                    border-radius: 100%;
                }
                .info {
                    .name {
                        font-size: 14px;
                        color: var(--gray-900);
                        margin: 0;
                    }
                    .email {
                        font-size: 12px;
                        color: var(--gray-600);
                        margin: 0;
                    }
                }
            }
            .actions {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

/**
 ===== USAGE PAGE =========
 */

/**
 ===== ORGANIZATION PAGE =========
 */
.account-organization {
    .form-body {
        width: 500px;
    }
}

/**
====== PAYMENT METHODS =====
 */

.invite-user-modal-form {
    display: grid;
    grid-template-columns: auto 200px;
    grid-template-rows: 45px;
    grid-column-gap: 15px;
}
