$breakpoint-alpha: 480px;

.card {
    background-color: white;
    border-radius: 8px;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 10px;
    }

    &.character-card {
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        .about-character {
            white-space: pre-wrap;
            line-height: 150%;
            font-weight: 400;
            font-size: 15px;
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
        }
    }

    .character-info {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 12px;

        img {
            height: 30px;
            border-radius: 50%;
        }

        .character-name {
            font-size: 15px;
            font-weight: bold;
        }
    }

    .card-question {
        display: flex;
        background-color: #f4f6fc;
        border-radius: 8px;
        gap: 15px;
        align-items: center;

        img {
            height: 35px;
            border-radius: 100%;
        }

        p.name {
            font-size: 15.6px;
            font-weight: 500;
        }
    }

    .card-meta {
        .arin-name {
            font-weight: bold;
        }

        .actions {
            display: flex;
            align-items: center;

            .icon {
                font-size: 24px;

                &.rotate {
                    font-size: 22px;
                    transform: rotateZ(45deg);
                }
            }
        }
    }

    .card-response {
        font-size: 15px;
        font-weight: 400;
        line-height: 150%;
        // transition: all 0.6s linear;
        white-space: pre-wrap;
        border-left: 0.8px solid var(--gray-300);

        strong {
            font-weight: bold;
        }
    }

    // Preview section
    .preview-container {
        border-radius: 8px;
        // background-color: var(--gray-50);
        padding: 12px 0;
        margin: 10px 0;

        .preview-section {
            border-radius: 6px;
            border: 1px solid var(--gray-300);
            background-color: white;
            padding: 0 12px 12px;

            .preview-table-container {
                width: 100%;
                max-height: 650px;
                overflow-x: auto;

                &::-webkit-scrollbar {
                    height: 8px;
                    height: 8px;
                }
            }

            .preview-table {
                width: 100%;
                border-collapse: separate;

                /* Apply a border to the bottom of all but the last row */
                thead > tr:not(:last-child) > th,
                thead > tr:not(:last-child) > td,
                tbody > tr:not(:last-child) > th,
                tbody > tr:not(:last-child) > td,
                tfoot > tr:not(:last-child) > th,
                tfoot > tr:not(:last-child) > td,
                tr:not(:last-child) > td,
                tr:not(:last-child) > th,
                thead:not(:last-child),
                tbody:not(:last-child),
                tfoot:not(:last-child) {
                    border-bottom: 1px solid var(--gray-300);
                }

                tr {
                    border-bottom: 1px solid var(--gray-400);
                }

                th {
                    display: none; // for accessibility, use a visually hidden method here instead!
                }

                td {
                    display: block;

                    &:first-child {
                        padding-top: 0.5em;
                    }

                    &:last-child {
                        padding-bottom: 0.5em;
                    }

                    &:before {
                        content: attr(data-th) ": "; // who knew you could do this? The internet, that's who.
                        font-weight: bold;

                        // optional stuff to make it look nicer
                        width: 6.5em; // magic number :( adjust according to your own content
                        display: inline-block;
                        // end options

                        @media (min-width: $breakpoint-alpha) {
                            display: none;
                        }
                    }
                }

                th,
                td {
                    text-align: left;

                    @media (min-width: $breakpoint-alpha) {
                        display: table-cell;
                        padding: 0.25em 0.5em;
                    }
                }
            }

            .preview-file {
                border: 1px solid var(--gray-300);
                border-radius: 8px;
                background-color: var(--gray-50);
                display: grid;
                align-items: center;
                grid-template-columns: 30px auto 45px;
                min-height: 50px;
                margin: 40px 0 0;
                padding: 0 10px;

                .leading {
                    .icon {
                        font-size: 24px;
                    }
                }

                .action {
                    .icon {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .card-expand {
        .icon {
            font-size: 26px;
        }
    }

    .edit-box {
        .text-box {
            min-height: 180px;
        }
    }

    .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 32px;

        .icon {
            color: var(--gray-700);

            &.positive {
                color: #2196f3;
            }

            &.negative {
                color: #d9214d;
            }
        }

        .sentiment-message {
            display: flex;
            align-items: center;

            .icon {
                font-size: 18px !important;

                &.positive {
                    transform: translateY(-3px);
                }

                &.negative {
                    transform: translateY(3px);
                }
            }
        }

        .card-date {
            font-weight: 300;
            font-size: 12px;
        }

        .card-footer-actions {
            display: flex;
            align-items: center;
            // gap: 10px;
            .sentiment-message {
                font-size: 12px;
            }

            .icon-buttons {
                height: 35px;
                width: 35px;
                padding: 0;
                display: inline-block;
                text-align: center;

                .icon {
                    display: inline;
                    font-size: 20px;

                    &.save-icon {
                        font-size: 24px;
                    }
                }
            }

            .icon {
                font-size: 24px;

                &.rotate {
                    font-size: 16px;
                    transform: rotateZ(45deg);
                }
            }
        }
    }
}
