.main-pane-contents {
    .answers {
        width: 850px;
    }
}

.view-approved-query-modal {
    .character-name {
        font-size: 15px;
        font-weight: 500;
    }

    .reply {
        font-size: 15px;
    }
}
