@import "./mixins/grid";

.chat-box {
    --chat-background: rgba(10, 14, 14, 0.95);
    --chat-panel-background: #0e0e0e;
    --chat-bubble-background: #14181a;
    --chat-bubble-active-background: #171a1b;
    --chat-add-button-background: #212324;
    --chat-send-button-background: #8147fc;
    --chat-text-color: #a3a3a3;
    --chat-options-svg: #a3a3a3;
}

$modal-width: 50vw;
$modal-sm-width: 95vw;
$chat-conversation-panel: 85px;
$chat-header: 65px;

.chat-modal-content {
    position: absolute !important;
    bottom: 0 !important;
    margin: 0 !important;
    max-width: $modal-sm-width !important;
    width: $modal-sm-width !important;
    height: 90vh;
    box-shadow: 12px -6px 24px rgba(0, 0, 0, 0.12) !important;

    @include media-gt-tablet {
        height: 90vh;
        max-width: $modal-width !important;
        width: $modal-width !important;
    }
}

#chat {
    background: var(--chat-panel-background);
    // margin: 25px auto;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    height: 100%;
    overflow: hidden;
}

#chat::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.chakra-modal__content-container {
    background: rgba(0, 0, 0, 0.7) !important;
}

.chat-header {
    position: relative;
    height: $chat-header;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 0 20px 0 35px;
    border-bottom: 1px solid var(--gray-800);
    background: #000000;

    .neo-modal-close {
        position: absolute;
        top: 50%;
        right: 24px;
        font-size: 20px;
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        color: white;
        border: 0.5px solid var(--gray-50);
        transform: translateY(-50%);
        transition: all 0.3s linear;
        &:hover {
            background-color: white;
            .icon {
                color: black !important;
            }
        }
    }
}

.chat-header {
    .photo {
        height: 40px;
        width: 40px;
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid var(--gray-800);
        outline: 1px solid var(--chat-send-button-background);
        img {
            height: 40px;
            width: 40px;
            object-fit: cover;
            transform: scale(1.4);
        }
    }

    .info {
        .name {
            font-size: 16px;
            color: white;
            margin: 0;
            font-weight: 600;
        }

        .title {
            margin: 0;
            font-size: 14px;
            color: var(--gray-400);
        }
    }

    .actions {
        .icon {
            color: white;
            font-size: 18px;
        }
    }
}

#chat .btn-icon {
    position: relative;
    cursor: pointer;
}

#chat .btn-icon svg {
    stroke: #fff;
    fill: #fff;
    width: 50%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#chat .chat__conversation-board {
    padding: 15px 15px 20px 15px;
    height: 80vh;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    @include media-gt-tablet {
        height: 82.5vh;

        padding-bottom: 65px;
    }
    @include media-desktop {
        height: 83vh;
    }
}

#chat .chat__conversation-board__message-container.reversed {
    flex-direction: row-reverse;
}

#chat
    .chat__conversation-board__message-container.reversed
    .chat__conversation-board__message__bubble {
    position: relative;
}

#chat
    .chat__conversation-board__message-container.reversed
    .chat__conversation-board__message__bubble
    .message_contents {
    background: #8147fc;
    color: white;
}

#chat
    .chat__conversation-board__message-container.reversed
    .chat__conversation-board__message__bubble
    .message_contents:not(:last-child) {
    margin: 0 0 10px 0;
}

#chat
    .chat__conversation-board__message-container.reversed
    .chat__conversation-board__message__person {
    // margin: 0 0 0 15px;
}

#chat
    .chat__conversation-board__message-container.reversed
    .chat__conversation-board__message__options {
    align-self: center;
    position: absolute;
    left: 0;
    display: none;
}

#chat .chat__conversation-board__message-container {
    position: relative;
    display: flex;
    flex-direction: row;
}

#chat
    .chat__conversation-board__message-container:hover
    .chat__conversation-board__message__options {
    display: flex;
    align-items: center;
}

#chat
    .chat__conversation-board__message-container:hover
    .option-item:not(:last-child) {
    margin: 0 0.5em 0 0;
}

#chat .chat__conversation-board__message-container:not(:last-child) {
    margin: 0 0 10px 0;
}

#chat .chat__conversation-board__message__person {
    text-align: center;
    // margin: 0 10px 0 0;
}

#chat .chat__conversation-board__message__person__avatar {
    height: 35px;
    width: 35px;
    overflow: hidden;
    border-radius: 50%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    ms-user-select: none;
    position: relative;
    display: none;
}

#chat .chat__conversation-board__message__person__avatar::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
}

#chat .chat__conversation-board__message__person__avatar img {
    height: 100%;
    width: auto;
}

#chat .chat__conversation-board__message__person__nickname {
    font-size: 9px;
    color: #484848;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: none;
}

#chat .chat__conversation-board__message__context {
    max-width: 100%;
    // width: 100%;
    align-self: flex-end;
}

#chat .chat__conversation-board__message__options {
    align-self: center;
    position: absolute;
    right: 0;
    display: none;
}

#chat .chat__conversation-board__message__options .option-item {
    border: 0;
    background: 0;
    padding: 0;
    margin: 0;
    height: 16px;
    width: 16px;
    outline: none;
}

#chat .chat__conversation-board__message__options .emoji-button svg {
    stroke: var(--chat-options-svg);
    fill: transparent;
    width: 100%;
}

#chat .chat__conversation-board__message__options .more-button svg {
    stroke: var(--chat-options-svg);
    fill: transparent;
    width: 100%;
}

/// Arin Coversation box
#chat .chat__conversation-board__message__bubble .message_contents {
    width: 100%;
    display: inline-table;
    word-wrap: break-word;
    background: var(--gray-900);
    font-size: 16px;
    color: white;
    padding: 10px 0.8em;
    line-height: 1.5;
    border-radius: 6px;
    font-weight: 300;
    border: 0.5px solid var(--gray-900);
    white-space: pre-wrap;
}

#chat .chat__conversation-board__message__bubble:not(:last-child) {
    margin: 0 0 0.3em;
}

#chat .chat__conversation-board__message__bubble:active {
    background: var(--chat-bubble-active-background);
}

#chat .chat__conversation-panel {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    border-radius: 12px;
    padding: 0 15px;
}

#chat .chat__conversation-panel__container {
    display: flex;
    flex-direction: row;
    background: #000;
    border-radius: 8px;
    padding: 10px 12px 10px 24px;
    align-items: center;
    height: 100%;
    border: 1px solid var(--gray-900);
}

#chat .chat__conversation-panel__container .panel-item:not(:last-child) {
    margin: 0 1em 0 0;
}

#chat .chat__conversation-panel__button {
    background: grey;
    height: 20px;
    width: 30px;
    border: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
}

#chat .chat__conversation-panel .send-message-button {
    background: var(--chat-send-button-background);
    height: 35px;
    min-width: 35px;
    border-radius: 50%;
    transition: 0.3s ease;
}

#chat .chat__conversation-panel .send-message-button:active {
    transform: scale(0.97);
}

#chat .chat__conversation-panel .send-message-button svg {
    margin: 1px -1px;
}

#chat .chat__conversation-panel__input {
    width: 100%;
    height: 100%;
    outline: none;
    position: relative;
    color: var(--chat-text-color);
    font-size: 16px;
    background: transparent;
    border: 0;
    resize: none;
}

.typing {
    width: 60px;
    height: 40px;
    border-radius: 20px;
    margin: 0 1rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle {
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #8d8d8d;
    margin: 3px;
}
.circle.scaling {
    animation: typing 1000ms ease-in-out infinite;
    animation-delay: 3600ms;
}

.circle:nth-child(1) {
    animation-delay: 0ms;
}

.circle:nth-child(2) {
    animation-delay: 333ms;
}

.circle:nth-child(3) {
    animation-delay: 666ms;
}

@keyframes typing {
    0% {
        transform: scale(1);
    }
    33% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.4);
    }
    100% {
        transform: scale(1);
    }
}

@media only screen and (max-width: 600px) {
    #chat {
        margin: 0;
        border-radius: 0;
    }
    #chat .chat__conversation-board {
        height: 72vh;
    }
    #chat .chat__conversation-board__message__options {
        display: none !important;
    }
}
