@import "mixins/grid";
$sidebar-width: 350px;
$search-box-height: 140px;
$toolbar-height: 55px;
$search-box-height: 135px;
$search-box-shrink-height: 73px;
$page-top-offset: 10px;

.folder-view-body {
    display: grid;
    height: 100%;
    grid-template-columns: auto 250px;
    grid-column-gap: 10px;
    @include media-custom-min(1000px) {
        grid-template-columns: auto 250px;
    }
    @include media-custom-min(1250px) {
        grid-template-columns: auto 300px;
    }
    @include media-custom-min(1500px) {
        grid-template-columns: auto 350px;
    }
    .main.column {
        height: 100%;
        overflow: hidden;
        padding-top: $page-top-offset;

        .folder-search-body {
            height: calc(
                100% -
                    (
                        #{$page-top-offset} + #{$toolbar-height} + #{$search-box-height}
                    )
            );
            overflow-y: auto;
            &.expand {
                height: calc(
                    100% -
                        (
                            #{$page-top-offset} + #{$toolbar-height} + #{$search-box-shrink-height}
                        )
                );
            }
            &::-webkit-scrollbar {
                width: 5px;
                display: none;
                border-radius: 8px;
            }
        }
        .folder-search-box-container {
            min-height: $search-box-height;
            position: relative;
            &.shrink {
                min-height: $search-box-shrink-height;
            }
        }
    }
}
