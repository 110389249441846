$chain-card-border: 1.2px solid var(--gray-400);
$header-height: 55px;
$footer-height: 45px;
.chaining-box {
    // position: absolute;
    // top: 15px;
    // right: 20px;
    max-width: 380px;
    // min-height: 400px;
    // bottom: 25px;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    background: white;
    transform: translateX(150%);
    transition: transform 0.2s linear;
    &.show {
        transform: translateX(0);
    }
    .chaining-header {
        height: $header-height;
        padding: 10px 14px;
        .title {
            font-size: 15px;
        }
        .subtitle {
            font-size: 12px;
        }
    }
    .chain-card-body {
        height: calc(100% - (#{$header-height} + #{$footer-height}));
        overflow-y: auto;
        .date {
            font-size: 12px;
        }
        .chain {
            position: relative;
            border-left: $chain-card-border;
            margin-bottom: 1.85rem;
            &::before {
                content: "";
                display: block;
                background: white;
                border: $chain-card-border;
                height: 15px;
                width: 15px;
                border-radius: 100%;
                position: absolute;
                top: 0;
                left: -7.5px;
            }
            &:not(:last-child)::after {
                content: "";
                border-left: $chain-card-border;
                position: absolute;
                bottom: -1.5rem;
                left: -1px;
                height: 2rem;
            }
            &:hover {
                background-color: rgba(0, 0, 0, 0.01);
                .action {
                    visibility: visible;
                }
            }
            .question {
                font-size: 14px;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
                text-overflow: ellipsis;
            }

            .action {
                visibility: hidden;
                cursor: pointer;
                .unchain-icon {
                    color: red;
                }
            }
        }
    }
    .chain-footer {
        border-top: 1px solid var(--gray-100);
        height: $footer-height;
    }
}
