.approved-card {
    background-color: white;
    border-radius: 8px;
    .card-question {
        display: flex;
        background-color: #f4f6fc;
        border-radius: 8px;
        gap: 15px;
        align-items: center;
        img {
            height: 30px;
            border-radius: 100%;
        }
        p.name {
            font-size: 15.6px;
            font-weight: 500;
        }
        .sentiment-message {
            .icon {
                font-size: 20px;
                &.positive {
                    color: #2196f3;
                }
                &.negative {
                    color: #d9214d;
                }
            }
        }
    }
    .card-meta {
        .user {
            display: flex;
            gap: 10px;
            img {
                height: 25px;
                width: 25px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
        .actions {
            .icon {
                font-size: 24px;
            }
        }
    }
    .card-response {
        font-size: 15px;
        line-height: 150%;
        white-space: pre-wrap;
    }
    .edit-box {
        .text-box {
            min-height: 180px;
            height: auto;
        }
    }
    .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-top: 2rem;
        .card-date {
            font-weight: 500;
        }
        .card-footer-actions {
            display: flex;
            align-items: center;
            gap: 10px;
            .icon {
                font-size: 18px;
            }
        }
    }
}
