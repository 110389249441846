.links-form-row {
    //display: grid;
    //background-color: red;
    //grid-template-columns: auto 45px;
    //width: 450px;
    position: relative;
    .input-form {
        width: 100%;
        input {
            width: 100%;
        }
    }
    .icon-action {
        position: absolute;
        top: 0;
        left: 100%;
        .icon {
            font-size: 20px;
        }
    }
}
