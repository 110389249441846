#left-panel {
    border-radius: 8px;
    padding-top: 10px;
    display: grid;
    grid-template-rows: 55px calc(100% - 55px);
    grid-template-columns: 100%;

    .switch-header {
        background-color: white;
        height: 100%;
        display: flex;
        justify-content: left;
        padding: 0 24px;
        align-items: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: 1px solid var(--gray-100);
    }

    .switch-body {
        height: 100%;
    }

    .sidebar-sm {
        border-radius: 0 !important;
    }
}
