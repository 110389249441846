.prompt-engineering {
    height: 100%;
    position: relative;
}

.image-bg {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
