@import "mixins/grid";

$sidebar-width-lg: 350px;
$sidebar-width-md: 300px;
$toolbar-height: 81px;

.search-layout-wrapper.store-memories {
    grid-template-columns: 250px auto 455px;
    .left-pane {
        overflow-y: hidden;
    }
    @include media-custom-min(1000px) {
        grid-template-columns: 250px 640px auto;
    }
    @include media-custom-min(1250px) {
        grid-template-columns: 300px 640px auto;
    }
    @include media-custom-min(1500px) {
        grid-template-columns: 340px 640px auto;
    }
}

.upload-side-bar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    background-color: #ffffff;
    border-right: 1px solid #dddddd;
    box-shadow: 0 4px 26px rgba(0, 0, 0, 0.12);
    @include media-gt-phone {
        width: $sidebar-width-md;
    }
    @include media-desktop {
        width: $sidebar-width-lg;
        // transition: all 0.4s ease-out;
    }
}

.upload-content-section {
    overflow-y: auto;
    position: relative;

    nav.store-memories-toolbar {
        height: $toolbar-height;
        background-color: white;
        width: 100%;
        box-shadow: 0 4px 40px rgba(0, 0, 0, 0.12);
        // display: flex;
        align-items: center;
        z-index: 4;
        border-bottom: 1px solid #dddddd;

        .subtitle {
            margin-top: -6px;
            height: 24px;
        }

        a {
            color: #2196f3;
        }
    }

    .main-pane-body {
        width: 100%;
        // margin-top: $toolbar-height;
        background-color: white;
        overflow-y: auto;

        &.store-memories {
            height: calc(100% - 55px);
            padding: 16px 24px 0 !important;
        }

        .form {
            height: 100%;
            overflow-y: auto;
            padding-bottom: 36px;

            &::-webkit-scrollbar {
                display: none;
            }

            .input-section {
                width: 100%;
                @include media-custom-min(1367px) {
                    width: 100%;
                }
            }
        }
    }
}

.store-memories-map {
    .map-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 198px;
        background-color: white;
        z-index: 10;
    }
    .map {
        position: absolute;
        left: 0;
        top: 130px;
        right: 0;
        bottom: 0;
    }
}

.form-preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 100;
    .no-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 200px;
        .icon {
            font-size: 36px;
        }
        .title {
            font-weight: bold;
            font-size: 15px;
            margin: 12px 0 0px;
        }
        .subtitle {
            font-size: 13px;
        }
    }
    .preview-container {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        display: grid;
        height: 183px;
        grid-template-columns: 200px 200px;
        // width: 280px;
        // background-color: var(--gray-50);
        border-bottom: 1px solid var(--gray-100);

        .small-text {
            font-size: 13px;
            font-weight: 300;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
