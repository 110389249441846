.search-layout-wrapper {
    .main-grid {
        display: grid;
        grid-template-rows: 55px auto 67px;

        .toolbar {
            margin-left: 0;
        }

        .about-card {
            background-color: white;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
}

.search-view {
    position: relative;

    .empty-collaborate-message {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;

        .icon {
            font-size: 36px;
            margin-bottom: 5px;
        }
    }
}

.applications-searchbox {
    width: 100%;
    background-color: white;
    position: relative;
    height: 56px;
    border-radius: 8px;
    border: 1px solid var(--gray-100);

    &:focus-within {
        border: 1.5px solid var(--blue-600);
    }

    input {
        background-color: transparent;
        height: 100%;
        width: 87%;
        line-height: 56px;
        padding: 0 18px;
        //&:disabled {
        //    background-color: var(--gray-50);
        //}
        &:focus {
            outline: none;
        }
    }

    .spinner-container {
        position: absolute;
        top: 50%;
        right: 72px;
        transform: translateY(-34%);
    }

    .actions {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        padding-right: 16px;

        .action-btn {
            padding: 8px 0 8px 8px;

            .icon {
                color: var(--gray-500) !important;
                font-size: 22px;
            }
        }
    }
}

.applications-searchbox-view-mode {
    text-align: center;
    color: gray;
    padding-top: 15px;
}
