.upload-data-panel {
    .chakra-accordion__button {
        background-color: transparent;
        padding: 0 10px;
        width: 100%;
        &:hover {
            background-color: transparent;
        }
    }
}

.accordion-header {
    display: grid;
    grid-template-columns: auto 28.5px;
    border-bottom: 1px solid var(--gray-200);
    font-size: 15px !important;
}
.location-accordion {
    .accordion-header {
        border-bottom: none;
        grid-template-columns: auto 22.5px;
    }
}
.accordion-inner-panel {
    // border-left: 1px dotted var(--gray-200);
    padding: 8px 6px 0 10px !important;
}
.location-accordion {
    border: none !important;
    border-bottom: 1px solid var(--gray-200) !important;
    &:last-child,
    &:only-child {
        border: none !important;
    }
}

.accordion-edit-form {
    display: grid;
    align-items: center;
    grid-template-columns: auto 58px;
    input {
        height: 25px;
        width: 100%;
        border-radius: 4px;
        outline: 2px solid var(--blue-500);
        padding: 0 5px;
    }
    button {
        height: 30px;
        width: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 16px;
        &.save {
            color: var(--green-500);
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}

.accordion-sector {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    .accordion-sector-title {
        font-size: 15px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
            font-size: 12px;
        }
    }
    &:hover .memory-actions {
        display: block;
        background-color: white;
    }
    .memory-actions {
        display: none;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        button {
            font-size: 16px;
            height: 24px;
            width: 24px;
            text-align: center;
            .icon {
                display: inline;
            }
        }
    }
}

.tree-file-item {
    position: relative;
    display: grid;
    grid-template-columns: 18px auto;
    grid-column-gap: 5px;
    align-items: center;
    border-bottom: 1px solid #ddd;
    &:last-child {
        border: none;
    }
    .icon {
        font-size: 18px;
    }
    .file-name {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .memory-actions {
        display: none;
        background-color: white;
        z-index: 3;
        justify-content: center;
        align-items: center;
        gap: 3px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-48%);
        transition: all 0.4s linear;
    }

    &:hover {
        .memory-actions {
            display: flex;
        }
    }
}

.file-edit-form {
    display: grid;
    align-items: center;
    grid-template-columns: 22px auto 58px;
    .icon {
        font-size: 18px;
    }
    input {
        height: 25px;
        width: 100%;
        border-radius: 4px;
        outline: 2px solid var(--blue-500);
        padding: 0 5px;
    }
    button {
        height: 30px;
        width: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 16px;
        &.save {
            color: var(--green-500);
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}

.memories-action-footer {
    position: absolute;
    height: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-content: center;
}
