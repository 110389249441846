@import "mixins/grid";

$page-navigation-transition: transform 0.5s linear;
$sm-sidenav-width: 200px;

/**
  * Force landscape orientation on tablet devices
**/
@media screen and (min-width: 768px) and (orientation: portrait) {
    #landing-page-view {
        transform: rotate(90deg);
        transform-origin: left top;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        position: fixed;
        top: 0;
        left: 0;
    }

    #orientation-message {
        display: block;
    }
}

#landing-page-view {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    height: 100vh;
    width: 100vw;
    font-family: "SF Pro Text", sans-serif;
    background-color: white;

    @include media-gt-tablet {
        white-space: unset;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .landing-page-wrapper {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
        transition: transform 0.2s ease-in;

        &.side-open {
            transform: translateX(-#{$sm-sidenav-width});
        }

        @include media-gt-tablet {
            display: block;
            transform: translateX(0);
        }
    }

    .mobile-nav-links-container {
        position: absolute;
        right: -$sm-sidenav-width;
        top: 0;
        bottom: 0;
        width: $sm-sidenav-width;
        height: 100%;
        padding: 0 15px;
        background-color: #000000;
        transition: transform 0.2s ease-in;
        z-index: 9999;
        overflow-y: auto;

        &.side-open {
            transform: translateX(-#{$sm-sidenav-width});
        }

        .close-btn {
            position: absolute;
            left: 0;
            top: 33px;
            height: 2px;
            width: 25px;
            background: white;

            &::after {
                content: "";
                position: absolute;
                right: 0;
                border-right: 2px solid white;
                height: 30px;
                top: 50%;
                transform: translateY(-50%);
            }

            svg {
                color: white;
                font-size: 36px;
            }
        }

        ul.main-links {
            padding-top: 50px;

            @include media-custom-min(375px) {
                padding-top: 80px;
            }

            li {
                color: white;
                font-size: 18px;
                padding: 15px;
            }
        }

        ul.bottom-links {
            position: absolute;
            bottom: 12%;
            color: var(--gray-400);
            padding-top: 14px;

            &::before {
                content: "";
                position: absolute;
                top: -2px;
                left: 15px;
                width: 25%;
                border-top: 0.5px solid #ffffff;
            }

            li {
                font-size: 15px;
                padding: 14px;
            }
        }

        .mobile-nav-links-footer {
            position: absolute;
            bottom: 0;
            text-align: center;
            left: 0;
            right: 0;
            padding-bottom: 20px;
            font-size: 12px;
            color: var(--gray-500);
        }

        @include media-gt-tablet {
            display: none;
        }
    }
}

.landing-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 75px;
    padding: 0 16px;
    display: flex;
    z-index: 15;
    justify-content: space-between;
    align-items: center;

    &.white-bg {
        img.logo.white {
            display: none;
        }

        img.logo.black {
            display: block !important;
        }

        .sm-toggle-sidenav .landing-nav-links-icon {
            color: #1e1e1e !important;
        }
    }

    @include media-gt-tablet {
        height: 105px;
        padding: 0 36px 0 24px;
    }

    .landing-nav-links {
        &.sm {
            display: block;

            @include media-gt-tablet {
                display: none;
            }
        }

        &.lg {
            display: none;
            @include media-gt-tablet {
                display: block;
            }
        }

        li {
            display: inline-block;

            .landing-nav-link {
                position: relative;
                display: block;
                font-size: 14px;
                font-weight: 400;
                padding: 0 12px;
                color: white;

                &:hover {
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -7px;
                        border: 1.5px solid var(--gray-700);
                        width: 35%;
                        left: 50%;
                        transform: translateX(-50%);
                        border-radius: 8px;
                    }
                }

                @include media-gt-tablet {
                    font-size: 19px;
                    padding: 0 15px;
                    color: var(--gray-700);
                }

                &.active {
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -7px;
                        border: 1.5px solid var(--gray-700);
                        width: 35%;
                        left: 50%;
                        transform: translateX(-50%);
                        border-radius: 8px;
                    }
                }
            }

            img.logo {
                cursor: pointer;
                height: 25px;

                &.black {
                    display: none;
                }

                @include media-gt-tablet {
                    height: 35px;
                }
            }
        }
    }

    .sm-toggle-sidenav {
        .landing-nav-links-icon {
            font-size: 36px;
            color: white;

            &.dark {
                color: #1e1e1e;
            }
        }

        @include media-gt-tablet {
            display: none;
        }
    }
}

#banner {
    .video-container,
    img {
        transition: transform 0.5s ease-in-out;
    }

    .text-contents {
        opacity: 0;
        display: none;
        transition: opacity 0.4s ease-out;

        &:first-child {
            opacity: 1;
            display: block;
        }

        &.text-context-no-inputs {
            padding-bottom: 14px;
            // Galaxy Fold
            @include media-custom-min(315px) {
                padding-bottom: 58px;
            }
            @include media-custom-min(500px) {
                padding-bottom: 0;
            }
        }

        &.intelligence-as-service {
            padding-bottom: 76px;
            // Galaxy Fold
            @include media-custom-min(280px) {
                padding-bottom: 42px;
            }
            @include media-custom-min(310px) {
                padding-bottom: 36px;
            }
            @include media-custom-min(375px) {
                padding-bottom: 54px;
            }
            @include media-custom-min(410px) {
                padding-bottom: 78px;
            }
            @include media-custom-min(500px) {
                padding-bottom: 0;
            }
        }

        &.embrace-infinity {
            padding-bottom: 54px;
            @include media-custom-min(280px) {
                padding-bottom: 62px;
            }
            @include media-custom-min(375px) {
                padding-bottom: 54px;
            }
            @include media-custom-min(412px) {
                padding-bottom: 79px;
            }
            @include media-custom-min(500px) {
                padding-bottom: 0;
            }

            .shrink-pwa-popup-action {
                animation: shrink 0.3s linear forwards;
            }
        }
    }

    &.active-state-2 {
        .img-container img,
        .video-container {
            transform: translateX(-100%);

            @include media-gt-tablet {
                transform: translateY(-100%);
            }
        }

        .text-contents {
            opacity: 0;
            display: none;

            &:nth-child(2) {
                opacity: 1;
                display: block;
            }
        }
    }

    &.active-state-3 {
        .img-container img,
        .video-container {
            transform: translateX(-200%);

            @include media-gt-tablet {
                transform: translateY(-200%);
            }
        }

        .text-contents {
            opacity: 0;
            display: none;

            &:nth-child(3) {
                opacity: 1;
                display: block;
            }
        }
    }

    &.active-state-4 {
        .img-container img,
        .video-container {
            transform: translateX(-300%);

            @include media-gt-tablet {
                transform: translateY(-300%);
            }
        }

        .text-contents {
            opacity: 0;
            display: none;

            &:nth-child(4) {
                opacity: 1;
                display: block;
            }
        }
    }
}

.feature-btn-section {
    position: absolute;
    top: -8%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primary-base);
        color: white;
        height: 50px;
        width: 50px;
        border-radius: 50%;

        &.hide {
            display: none;
        }

        .icon {
            color: white;
            font-size: 24px;
        }
    }

    @include media-gt-tablet {
        display: none;
    }
}

.feature-btn-sm {
    background-color: var(--primary-base);
    color: white;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    top: -9%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
        color: white;
        font-size: 24px;
    }

    @include media-gt-tablet {
        display: none;
    }
}

.feature-btn-container {
    display: none;
    margin-bottom: 10px;

    &.no-margin {
        margin: 0;
    }

    @include media-gt-tablet {
        display: flex;
        justify-content: center;
    }
}

.feature-btn {
    display: none;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
    transition: all 0.2s ease-out;
    padding: 8px 18px 8px 12px;
    border-radius: 8px;
    width: 100%;
    color: #000000;
    font-size: 19px;

    &.expand {
        padding: 8px;
    }

    @include media-gt-tablet {
        display: inline-flex;
        width: auto;
        justify-content: center;
    }

    .icon {
        font-size: 24px;
        transition: all 0.2s ease-out;
    }

    &.prev {
        padding: 12px;
        gap: 0;

        .icon {
            font-size: 15px;
            transition: all 0.2s ease-out;
        }

        &:hover {
            background-color: var(--gray-100);

            .icon {
                transform: translateX(0px);
            }
        }
    }

    &:hover {
        background-color: var(--gray-100);

        .icon {
            transform: translateX(6px);
        }

        .vertical-icon {
            transform: translateY(6px);
        }
    }
}

.waitlist-input-container {
    height: auto;
    max-height: 0 !important;
    width: 0;
    transition: all 0.3s ease;

    @include media-gt-tablet {
        height: 50px;
        max-height: 50px !important;
        width: 400px !important;
        margin: 0 auto;
    }

    .input-waitlist {
        position: relative;
        max-height: 0;
        height: 0;
        width: 0;
        border-radius: 0.375rem;
        -webkit-border-radius: 0.375rem;
        -moz-border-radius: 0.375rem;
        font-size: 16px;
        background-color: transparent;
        padding: 0 16px;
        outline: 0;
        transition: all 0.3s ease;
        // border: 1px solid #dddddd !important;

        @include media-gt-tablet {
            max-height: 50px;
            height: 50px;
            outline: 1px solid var(--gray-700);
            width: 100%;
            display: block;

            &:focus {
                outline: 1px solid var(--blue-400);
            }
        }
    }

    &.show {
        height: 50px;
        max-height: 50px !important;
        width: 100% !important;
        margin-bottom: 12px;

        .input-waitlist {
            max-height: 50px !important;
            height: 100% !important;
            width: 100% !important;
            border: 1px solid #8e8585 !important;
        }
    }
}

.waitlist-input {
    outline: none !important;
    box-shadow: none !important;
    font-size: 14px;
    color: white;
    max-height: 50px !important;
    height: 50px !important;
    width: 100% !important;
    border: 0.5px solid white !important;
    transition: all 0.5s ease-out;

    &::placeholder {
        font-weight: 400;
    }

    &.front-page-input {
        display: none;

        &.input-slide-in {
            opacity: 1;
            display: block !important;
        }

        @include media-gt-tablet {
            height: 50px !important;
            display: block;
        }
    }

    &.white {
        color: white !important;
        border: 0.5px solid white !important;
    }

    &:focus {
        border: 1px solid var(--blue-500) !important;
    }

    @include media-gt-tablet {
        height: 50px !important;
        outline: 0.5px solid #1e1e1e !important;
        display: block;
        color: #1e1e1e;
        width: 400px !important;
        // margin: auto;
    }
}

.join-btn {
    background-color: white;
    color: #1e1e1e;
    border-radius: 5px;
    transition: all 400ms linear;

    &.white {
        background-color: white !important;
        color: #1e1e1e !important;

        &:hover {
            background-color: var(--gray-200) !important;
        }
    }

    &.lg {
        display: none;
        @include media-gt-tablet {
            display: inline-flex;
        }
    }

    @include media-gt-tablet {
        &.sm {
            display: none;
        }
        background-color: var(--primary-base) !important;
        color: white !important;

        &:hover {
            background-color: var(--gray-700) !important;
        }
    }
}

.waitlist-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin: auto;

    .check-icon {
        font-size: 44px;
    }

    .title {
        font-size: 17px;
        margin: 0;
        color: white;
    }

    .text {
        font-size: 13px;
        font-weight: 400;
        margin: 0;
        color: var(--gray-100);
    }

    @include media-gt-tablet {
        width: 400px;

        .title {
            color: var(--gray-700);
        }

        .text {
            font-size: 15px;
            color: var(--gray-700);
        }
    }
}

.skeleton-loader {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    @include media-gt-tablet {
        display: grid;
        grid-template-columns: 45% 55%;
        color: #1e1e1e;
    }

    .text-container-skeleton {
        height: 100%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.overview-panel {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
    color: white;

    .col {
        height: 100%;
    }

    @include media-gt-tablet {
        display: grid;
        grid-template-columns: 45% 55%;
        color: #1e1e1e;
    }

    .img-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // z-index: -1;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transition: $page-navigation-transition;
        white-space: nowrap;

        @include media-gt-tablet {
            white-space: unset;
            position: relative;
            z-index: 0;
        }

        img {
            display: inline-flex;
            vertical-align: top;
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: $page-navigation-transition;

            @include media-gt-tablet {
                display: block;
            }
        }
    }

    .video-container {
        display: inline-flex;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transition: $page-navigation-transition;

        @include media-gt-tablet {
            display: block;
            // z-index: -1;
            position: relative;
            z-index: 0;
        }

        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: $page-navigation-transition;
        }
    }

    .text-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        transition: all 0.5s linear;

        @include media-gt-tablet {
            position: relative;
            align-items: center;
            padding-bottom: 0;
            color: #1e1e1e;
        }

        .text-contents {
            position: absolute;
            left: 10px;
            right: 10px;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            bottom: 0;
            padding: 45px 15px 35px;
            // height: 28%;
            text-align: center;
            white-space: pre-wrap;
            color: white;
            background-color: rgba(0, 0, 0, 0.6);
            transition: all 0.3s linear !important;

            &.input-text-contents {
                // height: 40%;
                // For screens with widths < 468px
                @include media-custom-min(468px) {
                    // height: 32%;
                }

                &.slide-up {
                    // height: 40%;
                }
            }

            .join-btn.sm {
                margin-top: -1px;
            }

            @include media-gt-tablet {
                position: relative;
                background-color: transparent;
                width: 580px;
                color: #1e1e1e;
                height: auto;
                padding: 0;
            }

            &.about {
                position: relative;
                display: none;
                @include media-gt-tablet {
                    display: block;
                    &::after {
                        content: "";
                        border-bottom: 1px solid var(--gray-200);
                        position: absolute;
                        width: 75%;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }

            .header,
            .banner-header {
                margin: 8px 0 15px;

                @include media-custom-min(250px) {
                    margin: 8px 0 15px;
                }

                @include media-custom-min(375px) {
                    margin: 8px 0 15px;
                }

                @include media-custom-min(410px) {
                    margin: 8px 0 15px;
                }

                @include media-gt-tablet {
                    margin: 8px 0 20px;
                }

                .title {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 5px;
                    line-height: 1;

                    @include media-custom-min(285px) {
                        font-size: 23px;
                    }

                    @include media-custom-min(375px) {
                        font-size: 24px;
                    }
                    @include media-custom-min(410px) {
                        font-size: 27px;
                    }

                    @include media-custom-min(468px) {
                        font-size: 27px;
                    }

                    @include media-gt-tablet {
                        font-size: 40px;
                        font-weight: bold;
                        margin-bottom: 15px;
                    }
                }

                .subtitle {
                    font-size: 14px;
                    font-weight: 400;
                    width: 100%;
                    margin: 12px auto 0;
                    color: var(--gray-50);

                    &.subtitle-larger {
                        @include media-custom-min(375px) {
                            width: 88%;
                        }

                        @include media-custom-min(400px) {
                            width: 96%;
                        }
                    }

                    @include media-custom-min(285px) {
                        font-size: 16px;
                    }

                    @include media-custom-min(375px) {
                        width: 80%;
                    }

                    @include media-custom-min(468px) {
                        width: 75%;
                    }

                    @include media-gt-tablet {
                        font-size: 18px;
                        width: auto;
                        color: var(--gray-700);
                    }
                }
            }

            .banner-header {
                @include media-gt-tablet {
                    margin: 5px 0 40px;
                }

                .expand-section {
                    transform: translateY(-5px);
                    margin-bottom: 10px;
                }
            }
        }
    }

    &.shared-panel {
        .text-container {
            justify-content: flex-end;

            .container-action {
                display: none;

                @include media-gt-tablet {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    z-index: 10;
                    position: absolute;
                    right: 19%;
                    top: 58%;
                }

                @include media-desktop {
                    right: 20%;
                }

                .container-action-btn {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    cursor: pointer;
                    align-items: center;
                    transition: all 0.2s linear;
                    padding: 12px;
                    border-radius: 8px;

                    &:hover {
                        gap: 10px;
                        background-color: var(--gray-100);
                    }

                    .icon {
                        font-size: 36px;
                        font-weight: 300;

                        &.up-icon {
                            transform: rotateZ(180deg);
                        }
                    }

                    .text {
                        font-size: 18px;
                    }
                }
            }

            .container-cards {
                position: relative;
                height: 39%;
                overflow-y: hidden;
                width: 100%;
                padding: 20px 0 0 10px;
                white-space: nowrap;
                vertical-align: middle;
                // transform: translateY(0);
                transition: transform 0.3s linear;
                display: flex;
                align-items: center;

                @include media-gt-tablet {
                    display: block;
                    padding: 20px 0 0 0;
                    white-space: unset;
                    overflow-y: auto;
                    height: 45%;
                    vertical-align: unset;
                }

                &::-webkit-scrollbar {
                    display: none;
                }

                @include media-gt-tablet {
                    height: calc(100vh - 320px);
                    &.pricing-cards {
                        height: calc(100vh - 450px);
                    }
                }

                .card-item-row {
                    position: relative;
                    flex: 0 0 95%;
                    display: inline-flex;
                    padding-right: 15px;
                    height: 100%;
                    width: 100vw;
                    @include media-gt-tablet {
                        display: grid;
                        grid-template-columns: 100px 350px 80px;
                        justify-content: center;
                        align-items: center;
                        grid-column-gap: 10px;
                        height: auto;
                        width: auto;
                        // padding: 0 40px;
                        margin-bottom: 15px;
                        margin-right: 0;
                    }

                    @include media-desktop {
                        grid-template-columns: 100px 350px 55px;
                    }

                    .left-col {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: -20px;
                        background-color: #1e1e1e;
                        height: 55px;
                        width: 55px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &.no-icon {
                            display: none;
                        }

                        @include media-gt-tablet {
                            text-align: center;
                            position: relative;
                            background-color: transparent;

                            &.no-icon {
                                display: block;
                            }
                        }
                    }

                    .right-col {
                        display: block;
                        z-index: 10;

                        .feature-btn-section {
                            top: 50%;
                            right: -15px;
                            gap: 0;
                            left: unset;
                            transform: translateY(-30%);
                        }

                        @include media-gt-tablet {
                            display: none;
                        }
                    }

                    &.learn-more {
                        width: 100%;
                        flex: 0 0 100%;
                        padding: 0;

                        .learn-more-card {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;

                            .feature-btn-section {
                                top: unset;
                                bottom: 12%;
                            }

                            @include media-gt-tablet {
                                display: block;

                                .feature-btn-section {
                                    display: none;
                                }
                            }
                        }
                    }

                    .icon {
                        display: inline-block;
                        font-size: 32px;
                    }

                    .card-item {
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                        padding: 35px 24px 0;
                        height: 100%;
                        width: 100%;
                        overflow-y: auto;
                        margin: auto;
                        text-align: center;
                        font-size: 18px;
                        color: white;
                        white-space: pre-wrap;
                        background-color: rgba(0, 0, 0, 0.8);

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        @include media-custom-min(300px) {
                            padding: 16px 24px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            overflow-y: hidden;
                        }

                        @include media-custom-min(410px) {
                            padding: 16px 30px;
                        }

                        @include media-gt-tablet {
                            height: 320px;
                            width: 320px;
                            margin: auto;
                            border: 1px solid var(--gray-200);
                            background-color: transparent;
                        }

                        &.black-card {
                            .title {
                                color: white;
                            }

                            @include media-gt-tablet {
                                background-color: black;
                                color: white;
                            }
                        }

                        .title {
                            font-weight: bold;
                            font-size: 25px;
                            margin-bottom: 15px;
                            color: white;
                            line-height: 1.2;

                            @include media-custom-min(375px) {
                                font-size: 32px;
                            }

                            @include media-gt-tablet {
                                color: black;
                            }
                        }

                        .description {
                            font-weight: 400;
                            font-size: 16px;

                            @include media-gt-tablet {
                                font-size: 18px;
                            }
                        }

                        .rating-container {
                            margin-bottom: 15px;
                            text-align: center;

                            .icon-row {
                                display: flex;
                                justify-content: center;
                                gap: 1px;

                                .icon {
                                    font-size: 16px;
                                    color: white;

                                    @include media-gt-tablet {
                                        color: black;
                                    }
                                }
                            }

                            .rating-name {
                                color: white;
                                font-weight: bold;

                                @include media-gt-tablet {
                                    color: black;
                                }
                            }
                        }

                        @include media-gt-tablet {
                            color: var(--gray-700);
                        }
                    }
                }

                .pricing-container {
                    display: flex;
                    gap: 15px;
                    justify-content: center;
                    padding: 0 16px;
                    color: white;

                    @include media-gt-tablet {
                        color: #1e1e1e;
                        padding: 0;
                    }
                }

                .pricing-card {
                    position: relative;
                    height: 250px;
                    width: 200px;
                    white-space: pre-wrap;
                    border-radius: 8px;
                    padding: 15px 20px;

                    .price {
                        font-size: 36px;
                        font-weight: bold;
                        margin-bottom: 4px;
                    }

                    .price-desc {
                        font-size: 16px;
                        color: white;

                        @include media-gt-tablet {
                            color: var(--gray-600);
                        }
                    }
                }
            }

            .action-btn {
                font-size: 16px;
                padding: 12px 15px;
                border-radius: 8px;
                border: none;
                outline: none;
                display: inline-flex;
                gap: 10px;
                color: white;
                background-color: #556b7b;

                @include media-gt-tablet {
                    font-weight: 400;
                    color: var(--blue-600);
                    background-color: transparent;
                }

                .icon {
                    font-size: 24px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .about-feature-btn-sm {
        background-color: white;
        height: 45px;
        width: 45px;
        border-radius: 50%;
        position: absolute;
        top: 47%;
        left: 43px;
        transform: translateY(-50%);

        .icon {
            color: #1e1e1e;
        }
    }

    .overview-footer {
        width: 100%;
        display: none;
        justify-content: center;
        margin-bottom: 24px;

        @include media-gt-tablet {
            display: flex;
        }

        ul {
            li {
                display: inline-block;
                padding: 0 5px;
                cursor: pointer;
                font-size: 15px;

                a {
                    cursor: pointer;
                }
            }
        }
    }
}

.pwa-prompt {
    position: absolute;
    height: auto;
    width: 98%;
    left: 50%;
    transform: translateX(-50%);
    background: black;
    border-radius: 8px;
    top: -105%;
    padding: 15px 15px;

    @include media-custom-min(375px) {
        width: 300px;
    }

    @include media-gt-tablet {
        top: -64%;
    }

    .pwa-prompt-header {
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        color: white;

        .title {
            font-weight: 500;
            font-size: 24px;
        }

        .icon {
            font-size: 24px;
        }
    }

    .description {
        color: var(--gray-300);
        margin-bottom: 15px;

        .icon {
            display: inline;
            font-size: 15px;
        }
    }

    .actions {
        display: grid;
        grid-template-columns: 45% 45%;
        grid-column-gap: 12px;
        justify-content: center;
        align-items: center;

        button {
            background-color: white;
            padding: 10px 0;
            color: #1e1e1e;
            width: 100%;
            border-radius: 5px;
        }
    }
}

#about {
    .video-container {
        position: relative;

        .actions {
            position: absolute;
            top: 60px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: flex-end;
            padding: 0 15px;
            z-index: 20;

            @include media-gt-tablet {
                top: unset;
                bottom: 30px;
                padding: 0 24px;
            }

            .action-btn {
                height: 35px;
                width: 35px;
                line-height: 35px;
                text-align: center;
                border-radius: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                font-weight: bold;

                .icon {
                    color: white;
                    font-size: 18px;
                    display: inline-block;
                }
            }
        }
    }
}

//
.four-cards-panel {
    position: relative;
    background-color: white;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 135px 20px 0;
    color: black;
    white-space: pre-wrap;

    .nav-back-action {
        position: absolute;
        top: 90px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        padding-bottom: 20px;

        .btn .icon {
            font-size: 24px;
        }

        @include media-gt-tablet {
            display: none;
        }
    }

    @include media-custom-min(300px) {
        padding: 130px 20px 0;
    }

    @include media-custom-min(420px) {
        padding: 140px 20px 0;
    }

    @include media-custom-min(500px) {
        padding: 155px 20px 0;
    }

    @include media-gt-tablet {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 155px 0 0;
    }

    .neo-logo {
        img {
            height: 35px;
            cursor: pointer;
        }
    }

    .title {
        font-size: 25px;
        font-weight: bolder;
        line-height: 1.2;
        margin-bottom: 15px;
        text-align: center;
        z-index: 1;

        @include media-gt-tablet {
            font-size: 40px;
        }
    }

    .subtitle {
        font-size: 15px;
        text-align: center;
        color: var(--gray-700);
        z-index: 1;
        width: 90%;
        margin: 0 auto;

        @include media-custom-min(378px) {
            width: 97%;
        }

        @include media-custom-min(500px) {
            font-size: 16px;
            width: 500px;
        }

        @include media-gt-tablet {
            width: 650px;
            font-size: 18px;
        }
    }

    .pricing-cards-row {
        margin-top: 25px;
        padding-top: 25px;
        border-top: 1px solid var(--gray-100);
        height: 48.5vh;
        overflow-y: auto;
        padding-bottom: 55px;

        &::-webkit-scrollbar {
            display: none;
        }

        @include media-custom-min-height(580px) {
            height: 56vh;
        }
        // Fix height
        @include media-custom-min-height(670px) {
            height: 70vh;
        }
        @include media-custom-min-height(720px) {
            height: 65.5vh;
        }
        @include media-custom-min-height(800px) {
            height: 68vh;
        }
        @include media-custom-min-height(900px) {
            height: 71vh;
        }
        @include media-custom-min-height(920px) {
            height: 88vh;
        }

        //@include media-custom-min(400px) {
        //    height: 71vh;
        //}

        @include media-gt-tablet {
            height: auto;
            display: flex;
            gap: 28px;
            margin-top: 45px;
            padding: 0;
            border-top: none;
        }

        .pricing-cards {
            position: relative;
            height: 238px;
            width: 100%;
            text-align: center;
            font-size: 18px;
            color: var(--gray-700);
            perspective: 1000px;
            margin: 0 auto 15px;

            @include media-custom-min(350px) {
                height: 318px;
                width: 318px;
                margin: 0 auto 15px;
            }

            @include media-custom-min(375px) {
                height: 330px;
                width: 330px;
                margin: 0 auto 15px;
            }

            @include media-custom-min(500px) {
                height: 330px;
                width: 330px;
                margin: 0 auto 25px;
            }

            @include media-gt-tablet {
                margin: unset;
                height: 280px;
                width: 280px;
            }

            .action-icon {
                position: absolute;
                top: 20px;
                right: 20px;
                font-size: 20px;
            }

            &.black {
                .card-inner {
                    color: white;
                    background-color: black;
                    border-radius: 8px;
                    border: 1px solid black;
                }
            }

            /* This container is needed to position the front and back side */
            .card-inner {
                position: relative;
                width: 100%;
                height: 100%;
                text-align: center;
                transition: transform 0.8s;
                transform-style: preserve-3d;
                border-radius: 8px;
                border: 1px solid var(--gray-200);
            }

            @include media-gt-tablet {
                &:hover .card-inner-flip {
                    transform: rotateY(180deg);
                }
            }

            &.flip-card {
                .card-inner-flip {
                    transform: rotateY(180deg);
                }
            }

            /* Position the front and back side */
            .card-front,
            .card-back {
                position: absolute;
                width: 100%;
                padding: 15px 28px;
                height: 100%;
                -webkit-backface-visibility: hidden; /* Safari */
                backface-visibility: hidden;
            }

            .card-front {
                display: flex;
                justify-content: center;
                align-items: center;

                .text {
                    font-size: 15px;

                    @include media-custom-min(370px) {
                        font-size: 16px;
                    }
                }
            }

            .card-back {
                text-align: left;

                .card-title {
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                .text {
                    font-size: 15px;

                    @include media-custom-min(370px) {
                        font-size: 16px;
                    }
                }
            }

            /* Style the back side */
            .card-back {
                transform: rotateY(180deg);
            }
        }

        .next-action-card-sm {
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include media-gt-tablet {
                display: none;
            }

            .next-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--primary-base);
                color: white;
                height: 50px;
                width: 50px;
                border-radius: 50%;
            }
        }
    }

    .four-cards-panel-footer {
        display: none;
        position: absolute;
        bottom: 10px;
        width: 100%;
        justify-content: center;
        margin-bottom: 24px;

        @include media-gt-tablet {
            display: flex;
        }

        ul {
            li {
                display: inline-block;
                padding: 0 5px;
                font-size: 15px;

                a {
                    font-weight: 400;
                }
            }
        }
    }
}

#collaboration {
    .collaboration-image {
        position: absolute;
        bottom: -60px;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 0;
        transform: scale(1.89);

        @include media-custom-min(375px) {
            bottom: -30px;
        }

        @include media-gt-tablet {
            transform: scale(1);
            bottom: -300px;
            width: 150%;
        }
    }

    .right-feature-sm-btn {
        position: relative;
        padding-top: 38px;
        display: flex;
        justify-content: center;
        z-index: 3;

        @include media-gt-tablet {
            display: none;
        }

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--primary-base);
            color: white;
            height: 50px;
            width: 50px;
            border-radius: 50%;

            .icon {
                color: white;
                font-size: 24px;
            }
        }
    }

    .right-feature-btn {
        display: none;
        z-index: 1;
        position: absolute;
        top: 20%;
        right: 28.5%;

        .feature-btn {
            flex-direction: column;
            padding-right: 12px !important;

            &:not(.prev) {
                padding-bottom: 20px;
            }
        }

        @include media-custom-max(1400px) {
            right: 30%;
        }

        @include media-gt-tablet {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // gap: 0px;
        }
    }
}

.modal-arrow-poin-paragraph {
    display: grid;
    grid-template-columns: 24px auto;
    grid-column-gap: 14px;

    .first-col {
        padding-top: 10px;
    }

    .icon {
        font-size: 16px;
    }
}

/***
 ======== Animations ====
 */
.slide-enter .img-container {
    opacity: 1;
    transform: translateY(100%);
}

.slide-enter-active .img-container {
    opacity: 1;
    transform: translateY(0%);
}

.slide-exit .img-container {
    opacity: 1;
    transform: translateY(0%);
}

.slide-exit-active .img-container {
    opacity: 1;
    transform: translateY(-50%);
}

.slide-enter-active .img-container {
    transition: opacity 300ms, transform 300ms;
}

.slide-exit-active .img-container {
    transition: opacity 500ms, transform 500ms;
}

.slide-enter .text-container {
    opacity: 0;
    // transform: translateY(100%);
}

.slide-enter-active .text-container {
    opacity: 1;
    // transform: translateY(0%);
}

.slide-exit .text-container {
    opacity: 1;
    // transform: translateY(0%);
}

.slide-exit-active .text-container {
    opacity: 0;
    // transform: translateY(-100%);
}

.slide-enter-active .text-container,
.slide-exit-active .text-container {
    // transition: opacity 300ms, transform 300ms;
}

/**
==== Skeleton animation
 */
.landing-page-skeleton {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1099;
    background-color: var(--gray-50);
    animation: 0.8s linear infinite alternate skeleton-loader;
}

@keyframes skeleton-loader {
    0% {
        border-color: var(--skeleton-start-color);
        background: var(--gray-50);
    }
    100% {
        border-color: var(--skeleton-end-color);
        background: var(--gray-200);
    }
}

@keyframes shimmer {
    100% {
        transform: translateY(100%);
    }
}

@keyframes shrink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        height: 0;
        display: none;
    }
}
