.activity-column {
    $header-height: 55px;
    $footer-height: 55px;

    .activity-header {
        height: $header-height;
        line-height: 5px;

        .title {
            font-size: 14px;
            margin: 0;
        }

        .subtitle {
            font-size: 12px;
            margin-top: -3px;
        }
    }

    .activity-body {
        height: calc(100% - (#{$header-height} + #{$footer-height}));
        overflow-y: auto;
        position: relative;

        .loading-animation {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            text-align: center;
            img {
                display: inline-block;
                margin-right: 5px;
                height: 45px;
            }
        }

        .cycle-card {
            position: relative;
            margin-bottom: 15px;
            // border-left: 2px solid var(--gray-200);
            &::after {
                position: absolute;
                content: "";
                border-left: 2px solid var(--gray-200);
                top: 0;
                bottom: -35px;
                left: -3px;
            }

            &:last-child {
                &::after {
                    bottom: -10px;
                }
            }

            .icon {
                z-index: 10;
                border: 2px solid var(--gray-200);
                position: absolute;
                top: -5px;
                font-size: 18px;
                background: white;
                height: 32px;
                width: 32px;
                left: -17px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                color: var(--gray-800);

                &.completed {
                    background-color: var(--green-500);
                    border: 2px solid var(--green-500);
                }
            }

            .text {
                font-size: 13px;
                padding-left: 14px;
            }
        }
    }

    .activity-footer {
        height: $footer-height;
        display: flex;
        gap: 10px;
        align-items: center;

        button {
            font-weight: 500 !important;
            font-size: 14px !important;
            color: #333333;
        }
    }
}
