.upload-form {
    border: 1.2px solid #dddddd;
    background-color: #f3f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    border-radius: 8px;
    color: #333333;

    .icon {
        font-size: 36px;
    }
}

//  selected file

.selected-file {
    position: relative;
    overflow: hidden;
    border: 1.2px solid #dddddd;
    background-color: #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    // height: 65px;
    border-radius: 8px;
    color: #333333;
    .info {
        display: flex;
        gap: 10px;
        .icon-section {
            .icon {
                font-size: 38px;
            }
        }
        .meta-data {
            .file-name {
                font-size: 16px;
                margin-bottom: 2px;
                display: flex;
                align-items: center;
                .name {
                    min-width: 35px;
                    max-width: 242.75px;
                    min-height: 24px;
                    background-color: white;
                    display: inline-block;
                    outline: 1.5px solid #dddddd;
                    border-radius: 4px;
                    padding: 0 3px;
                    cursor: text;
                }
            }
        }
    }
    .actions {
        .icons {
            font-size: 24px;
        }
    }

    .progress-section {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
