.progress-info {
    display: grid;
    grid-template-columns: auto 250px;
    align-items: center;
}
.progress-container {
    position: relative;
    width: 100%;
    height: 15px;
    border-radius: 20px;
    background-color: var(--gray-50);
    overflow: hidden;

    .progress-bar {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 50%;
        border-radius: 100px;
        background-color: var(--primary-base);
    }
}
