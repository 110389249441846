.search-container {
    // background-color: white;
    position: absolute;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    min-height: 45px;
    // overflow-x: hidden;
    bottom: 0;
    width: 100%;
    // left: 50%;
    // transform: translateX(-50%);
    z-index: 10;
    border: 1px solid var(--gray-100);
    // box-shadow: 0 -4px 30px 4px rgba(0,0,0,0.12);
    &.slide-left {
        width: 850px;
        transform: translateX(-50.1%);
    }

    .search-box {
        border-radius: 18px;

        .input-group {
            position: relative;
            border: 1px solid #ddd;
            height: auto;
            width: 100%;
            padding: 0 16px;
            border-radius: 8px;

            &:focus-within {
                outline: 1px solid #2196f3;
                border: 1px solid #2196f3;
            }

            .search-icon-container {
                position: absolute;
                top: 0;
                height: 45px;
                line-height: 45px;

                .search-icon {
                    font-size: 25px;
                    display: inline-block;
                    color: #999999;
                }
            }

            .notes-action {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 150px;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 12px;

                .vertical {
                    width: 50px;
                    flex-direction: column;
                }

                .icon {
                    font-size: 26px;
                    color: var(--gray-600);
                }
            }

            .input-box {
                min-height: 45px;
                margin-left: 20px;

                textarea {
                    width: 100%;
                    min-height: 24px;
                    line-height: 24px;
                    // overflow-y: hidden;
                    outline: none;
                    transition: height 0.05s linear;
                }

                .search-input-box {
                    width: 100%;
                    min-height: 45px;
                    max-height: 400px;
                    padding: 10px;
                    font-size: 14px;
                    line-height: 25px;
                    outline: none;
                    overflow-y: auto;
                    //overflow: hidden;
                    .prompt {
                        display: inline-flex;
                        font-size: 12px;
                        border: 1px solid var(--blue-300);
                        border-radius: 5px;
                        margin: 0 2px 4px;
                        align-items: center;

                        .prompt-title {
                            background-color: var(--blue-300);
                            color: white;
                            height: 25px;
                            padding: 0 5px;
                            border-top-left-radius: 5px;
                            border-bottom-left-radius: 5px;
                        }

                        .prompt-input {
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 5px;
                            width: 75px;
                            max-width: 100px;
                            height: 25px;
                            padding: 0 4px;
                            // border: 1px solid var(--gray-100);
                            outline: none;
                        }
                    }
                }
            }
        }

        .tone-box {
            display: flex;
            gap: 20px;

            .tone-slot {
                height: 48px;
                min-width: 150px;

                .tone-input-element {
                    display: none;
                }

                .tone-card {
                    padding: 6px 10px;
                    // outline: 1px solid #ddd;
                    background-color: white;
                    border-radius: 6px;

                    .card-icon {
                        .icon {
                            color: #333;
                            font-size: 22px;
                        }
                    }

                    .card-content {
                        .title {
                            color: #333;
                            font-size: 14px;
                            font-weight: 500;
                        }

                        .subtitle {
                            font-size: 12px;
                        }
                    }
                }

                .tone-input-element:checked + .tone-card {
                    // outline: 1px solid #ddd;
                    background-color: rgba(0, 0, 0, 0.06);
                    // box-shadow: 0 6px 24px rgba(0, 0, 0, 0.02);
                    .card-icon {
                        .icon {
                            // color: #2196f3;
                        }
                    }

                    .card-content {
                        .title {
                            // color: #2196f3;
                        }
                    }
                }
            }
        }

        .settings-card {
            padding: 0 10px;
            background-color: white;
            border-radius: 6px;

            .card-icon {
                .icon {
                    color: #333;
                    font-size: 22px;
                }
            }

            .card-content {
                .title {
                    color: #333;
                    font-size: 14px;
                    font-weight: 500;
                }

                .subtitle {
                    font-size: 12px;
                }
            }
        }

        .input-controls {
            display: grid;
            grid-template-columns: auto 150px;
            column-gap: 5px;
            &.expand {
                grid-template-columns: auto 40px;
            }

            .controls-toggle {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                &.expand {
                    flex-direction: column;
                }
                button.action-btn {
                    padding: 5px;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.05);
                    }

                    .icon {
                        font-size: 20px;
                        color: var(--gray-600);
                    }
                }
            }
        }

        .controls-form {
            transition: all 0.3s linear;
            // height: 1px;
            // opacity: 0;
        }
    }
}

.chaining-progress-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;

    .progress-bar {
        height: 100%;
        width: 0;
        transition: width 0.3s ease-out;
        background-color: var(--gray-700);
    }
}

.settings-drawer-body {
    font-size: 15px;

    .header {
        .icon {
            img {
                height: 45px;
            }
        }
    }

    .settings-text-head {
        line-height: 1.5;
        .title {
            font-weight: 500;
        }
    }

    .settings-title {
        font-size: 14px !important;
        font-weight: 500;
    }

    .chakra-accordion__button:hover {
        background-color: transparent !important;
    }
}

/**
 ============== NOTES SECTION ===========
 */
.chakra-accordion__button {
    &:hover {
        background-color: transparent !important;
    }
}

/**
 ============== MEMORY SELECT ===========
 */
.arin-memory-select-container .arin-react-select__control {
    background-color: white;
    width: 280px;
    height: 48px;
    border-color: #ececf1;

    &:hover {
        border-color: #dddddd;
    }
}

.arin-memory-select-container .arin-react-select__control--is-focused {
    border-color: var(--primary-base);
    box-shadow: none;

    &:hover {
        border-color: var(--primary-base);
    }
}

.arin-memory-select-container .arin-react-select__menu {
    background-color: white;
    border: 0 solid var(--gray-100);
}

.arin-memory-select-container .arin-react-select__option {
    color: #6e6e80;
    background-color: white;

    &:hover {
        color: var(--gray-50);
        background-color: var(--primary-base);
    }
}

/* .arin-memory-select-container .arin-react-select__option--is-focused {
  @apply bg-gray-200 bg-gray-800;
} */

.arin-memory-select-container .arin-react-select__indicator-separator {
    background-color: var(--theme-bg);
}

.arin-memory-select-container .arin-react-select__input-container,
.arin-memory-select-container .arin-react-select__placeholder,
.arin-memory-select-container .arin-react-select__single-value {
    color: var(--gray-700);
}
