$toolbar-height: 55px;
.thread-toolbar {
    height: $toolbar-height;
    margin-left: 2px;
    margin-right: 1px;
    background-color: white;
    border-bottom: 1px solid var(--gray-100);
    padding: 0 16px;
    display: grid;
    grid-template-columns: 160px auto 180px;
    grid-column-gap: 12px;
    align-items: center;
    .twin-avatars {
        display: flex;
        img {
            height: 35px;
            border-radius: 100%;
            margin-left: -5px;
            outline: 3px solid var(--gray-50);
            &:first-child {
                margin-left: 0;
                border: none;
            }
        }
    }
    .question-title {
        width: 400px;
    }
    .icon {
        font-size: 24px;
    }
    .rotate {
        transform: rotateZ(45deg);
    }
    .icon-buttons {
        padding: 5px;
        text-align: center;
        margin-right: 5px;
        border-radius: 6px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}

.thread-page-body {
    height: calc(100% - #{$toolbar-height});
    width: 100%;
    overflow-y: auto;
}
.thread-search-view {
    padding-right: 2px;
}

.thread-card {
    // border-bottom: 1px solid var(--gray-300);
    // padding-bottom: 2rem;
    .card-question {
        display: flex;
        background-color: #f4f6fc;
        border-radius: 8px;
        gap: 15px;
        align-items: center;
        img {
            height: 35px;
            border-radius: 100%;
        }
        p.name {
            font-size: 15.6px;
            font-weight: 500;
        }
    }
    .answer {
        font-size: 15px;
        line-height: 150%;
        // transition: all 0.6s linear;
        white-space: pre-wrap;
    }
}

.responses-thread {
    border-left: 0.8px solid var(--gray-300);
    padding-left: 25px;
}
