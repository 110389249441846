#persona-mcq {
    height: 25rem;
    border-radius: 0.3rem;
    min-height: 200px;

    .score-section {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        animation-name: score;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-delay: 0.2s;
    }

    .question-section {
        width: 100%;
        position: relative;
        padding: 0.5rem 0;
    }
    .question-count {
        font-size: 13px;
        color: var(--gray-900);
    }
    .question-count span {
        font-size: 16px;
        color: var(--gray-900);
    }
    .question-text {
        font-size: 18px;
        font-weight: 400;
        margin: 10px 0;
        color: var(--blue-900);
        border-bottom: 1px solid var(--gray-100);
        padding-bottom: 8px;
    }

    /* ANSWERS/RIGHT SECTION */
    .answer-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    button.answer-btn {
        display: block;
        width: 100%;
        font-size: 16px;
        background-color: var(--gray-50);
        border-radius: 8px;
        display: flex;
        padding: 10px;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid var(--gray-100);
        cursor: pointer;
        &.selected {
            border-color: var(--blue-100);
            background-color: var(--blue-50);
        }
        &:hover:not(.selected) {
            background-color: var(--gray-100);
        }

        &:focus {
            outline: none;
        }

        svg {
            margin-right: 5px;
        }
    }
}

@keyframes score {
    100% {
        font-size: 3rem;
    }
}
