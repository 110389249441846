$photo-size: 85px;
.profile-container {
    .profile-gradient {
        background: #8e9eab; /* fallback for old browsers */
        background: -webkit-linear-gradient(
            to right,
            #eef2f3,
            #8e9eab
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
            to right,
            #eef2f3,
            #8e9eab
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        // height: 200px;
        border-radius: 16px;
    }
    .profile-card {
        display: flex;
        gap: 15px;
        .image-container {
            position: relative;
            outline: 8px solid white;
            transform: translateY(-35%);
            border-radius: 50%;
            .upload-trigger {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.68);
                border-radius: 50%;
                padding: 5px;
                cursor: pointer;
                justify-content: center;
                align-items: center;
                transition: all 0.4s linear;
                svg {
                    height: 20px;
                    width: 20px;
                }
            }

            &:hover .upload-trigger {
                display: flex;
            }
        }
        img.photo {
            height: $photo-size;
            width: $photo-size;
            border-radius: 50%;
        }
        .name {
            font-size: 18px;
            font-weight: 500;
        }
    }
}
