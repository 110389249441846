.collaborate-message {
    // margin-right: -1px;
    border-left: 1px solid var(--gray-100);

    .ai-message {
        min-height: 50px;
        padding: 6px 16px;
        border: 1px solid var(--gray-100);
        border-left: none;
        background-color: white;
        display: grid;
        grid-template-columns: auto 120px;
        align-items: center;
        margin-bottom: 10px;
        &.with-control-buttons {
            grid-template-columns: auto 230px;
        }
        .character-name {
            font-size: 14px;
            font-weight: bold;
        }
        .character-message,
        .message {
            font-size: 14px;
        }
        .character-message-file-link {
            color: var(--blue-800);
            &:hover{
                color: var(--blue-900);
                text-decoration: underline;
            }
        }
        .assign-select {
            height: 35px !important;
            width: 100%;
            font-size: 14px;
        }
    }

    .user-message-container {
        padding: 10px 0 10px 25px;
        .user-message {
            border: 1px solid var(--gray-100);
            border-radius: 8px;
            padding: 14px;
            background-color: white;
            font-size: 14px;
            line-height: 140%;
            .character-name {
                font-size: 14px;
                font-weight: bold;
            }
            .character-message,
            .message {
                font-size: 14px;
            }
        }
    }
}

.input-message-form-container {
    .input-message-form {
        max-width: 350px;
        display: grid;
        grid-template-columns: auto 70px;
        align-items: center;
        grid-column-gap: 2px;
        margin-bottom: 10px;

        input {
            height: 35px;
            font-size: 14px;
            border: 1px solid var(--gray-100);
            border-radius: 4px;
            padding: 0 10px;
            width: 100%;
            min-width: 100px;
        }
        .actions {
            display: flex;
            gap: 4px;
            button {
                height: 30px;
                width: 30px;
                border-radius: 4px;
                background-color: var(--gray-50);
                transition: all 0.4s linear;
                display: flex;
                justify-content: center;
                align-items: center;

                &.save-btn {
                    &:hover {
                        background-color: var(--green-50);
                        color: var(--green-500);
                    }
                }

                &.cancel-btn {
                    &:hover {
                        background-color: var(--red-50);
                        color: var(--red-500);
                    }
                }
            }
        }
    }

    .input-message-text {
        display: grid;
        grid-template-columns: auto 50px;
        gap: 10px;

        p.text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .edit-btn {
            color: #2196f3;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
