@import "mixins/grid";
$search-box-height: 135px;
$search-box-shrink-height: 73px;
$toolbar-height: 55px;

.personas-view {
    height: 100%;
    display: grid;
    grid-template-columns: 250px auto;
    grid-column-gap: 10px;
    padding-top: 10px;
    overflow: hidden;
    @include media-custom-min(1000px) {
        grid-template-columns: 250px auto;
    }
    @include media-custom-min(1250px) {
        grid-template-columns: 300px auto;
    }
    @include media-custom-min(1500px) {
        grid-template-columns: 340px auto;
    }
}

.personas-body {
    position: relative;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 8px;
    height: 100%;
    .toolbar {
        width: 100%;
        height: $toolbar-height;
        .avatar {
            img {
                height: 35px;
                border-radius: 50%;
            }
        }
    }
    .persona-content {
        // height: calc(100% - (#{$toolbar-height} + #{$toolbar-height}));
        height: 100%;
        // height: 100%;
        background-color: white;
        padding: 20px 0 0 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow-y: auto;
        // &.expand {
        //     height: calc(
        //         100% - (#{$toolbar-height} + #{$search-box-shrink-height})
        //     );
        // }
        .persona-form {
            border-bottom: 1px solid var(--gray-100);
            .persona-form-body {
                display: grid;
                grid-template-columns: auto auto auto 250px;

                grid-column-gap: 15px;
                .form-col {
                    padding-top: 20px;
                    &:first-child {
                        padding-top: 0;
                    }
                }

                .form-label {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .label-text {
                        font-size: 13px;
                        font-weight: 500;
                    }
                }
            }
        }
        .persona-conversation {
            .input-control {
                width: 750px;
                margin: auto;
            }
            .persona-label {
                font-size: 14px;
                font-weight: 500;
            }
            .persona-input-box {
                margin: auto;
                height: 200px;
                width: 100%;
                border: 1px solid var(--gray-100);
                border-radius: 8px;
            }
        }
    }
}

.persona-card {
    border: 1px solid var(--gray-100);
    border-radius: 8px;
}
