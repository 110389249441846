@import "./mixins/grid";

.neo-modal-container {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);

        @include media-gt-tablet {
            background-color: rgba(0, 0, 0, 0.6);
        }

        &.neo-regular {
            display: flex;
            justify-content: center;
            align-items: flex-end;

            @include media-gt-tablet {
                align-items: center;
            }
        }

        &.neo-sheet {
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }
    }

    .neo-modal-body {
        box-shadow: 1px -5px 24px rgba(0, 0, 0, 0.6);
        transition: transform 90ms linear;

        @include media-gt-phone {
            box-shadow: unset;
        }
    }

    .neo-modal-paragraph {
        ol,
        ul {
            padding-left: 20px;
            counter-reset: list;

            &.letter-type {
                list-style-type: lower-alpha;
                padding-left: 22px;
                margin-top: 10px;

                li {
                    margin-bottom: 10px;
                    list-style: none;
                    position: relative;
                    padding-left: 10px;

                    &::before {
                        counter-increment: list;
                        content: "(" counter(list, lower-alpha) ")   ";
                        position: absolute;
                        left: -1.4em;
                    }
                }
            }

            &.roman-type {
                list-style-type: none;
                position: relative;
                padding-left: 22px;
                margin-top: 10px;

                &.sub {
                    padding-left: 45px !important;
                }

                li {
                    margin-bottom: 10px;
                    list-style: none;
                    position: relative;
                    padding-left: 10px;

                    &::before {
                        counter-increment: list;
                        content: "(" counter(list, lower-roman) ")   ";
                        position: absolute;
                        left: -1.4em;
                    }
                }
            }
        }
    }

    .neo-modal-close {
        position: absolute;
        top: -17px;
        left: 50%;
        font-size: 30px;
        height: 50px;
        width: 50px;
        display: flex;
        transform: translateX(-50%);
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        color: black;
        background-color: white;
        transition: all 0.3s linear;
        z-index: 999999;

        @include media-gt-tablet {
            height: 35px;
            width: 35px;
            font-size: 20px;
            border: 0.5px solid var(--gray-50);
            top: 30px;
            left: unset;
            right: 30px;
            transform: unset;
            background-color: transparent;
            color: white;
        }

        &:hover {
            background-color: white;
            color: black;
        }
    }

    img.neo-modal-image-cover {
        height: 100%;
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
        border: none;
        transform: scale(0);
        transition: transform 0.25s linear;

        &.scale-up {
            transform: scale(1);
        }
    }

    .neo-modal-banner {
        height: 85vh;
        width: 95.5vw;

        @include media-gt-tablet {
            height: 95vh;
        }

        .neo-modal-header {
            position: relative;
            height: 200px;
            background-color: black;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            box-shadow: 0 6px 24px rgba(0, 0, 0, 0.4);
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            white-space: pre-wrap;
            padding: 0 15px;

            @include media-gt-tablet {
                white-space: unset;
            }

            .modal-title {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 5px;
                text-align: center;

                @include media-gt-tablet {
                    font-size: 40px;
                    width: 650px;
                }
            }

            .modal-description {
                font-size: 14px;
                text-align: center;

                @include media-custom-min(375px) {
                    font-size: 15px;
                }

                @include media-gt-tablet {
                    font-size: 18px;
                    width: 650px;
                }
            }
        }

        .neo-modal-body {
            height: calc(100% - 200px);

            @include media-gt-tablet {
                padding: 0 36px;
            }

            .neo-modal-contents {
                height: 100%;
                background-color: white;
                overflow-y: auto;
            }

            .neo-modal-contents-inner {
                padding: 40px 20px;
                height: 100%;
                margin: auto;
                border-left: 1px solid var(--gray-200);
                border-right: 1px solid var(--gray-200);
                overflow-y: auto;
                white-space: break-spaces;

                @include media-gt-tablet {
                    width: 60%;
                    padding: 40px 60px;
                }

                &::-webkit-scrollbar {
                    display: none;
                }

                .content-section {
                    margin-bottom: 32px;
                    display: flex;
                    gap: 12px;

                    @include media-gt-tablet {
                        gap: 22px;
                    }

                    .icon-box {
                        padding-top: 6px;

                        .icon {
                            font-size: 24px;
                        }
                    }

                    .title {
                        font-size: 24px;
                        font-weight: 500;
                        margin-bottom: 15px;
                    }

                    p {
                        font-size: 15px;

                        @include media-gt-tablet {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }

    .neo-modal-sheet {
        height: 85vh;
        width: 95vw;
        background-color: black;
        position: relative;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        // background-image: url("../../public/ChangeisNigh.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include media-gt-tablet {
            height: 97vh;
        }

        .modal-sheet-overlay {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.4);
        }

        .neo-modal-contents {
            padding: 50px 24px 40px;
            color: white;
            position: relative;
            height: 100%;
            white-space: break-spaces;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            @include media-gt-tablet {
                padding: 120px 0 100px 100px;
                width: 40%;
            }

            .neo-logo {
                height: 37px;
                transform: translateX(-38px);

                @include media-gt-tablet {
                    height: 50px;
                    transform: translateX(-50px);
                }
            }

            .neo-modal-title {
                font-size: 17px;
                font-weight: bold;
                margin-bottom: 15px;

                @include media-custom-min(300px) {
                    font-size: 24px;
                }

                @include media-custom-min(380px) {
                    font-size: 26px;
                }
                @include media-gt-tablet {
                    font-size: 36px;
                }
            }

            .neo-modal-description {
                font-size: 15px;
                margin-bottom: 15px;

                @include media-custom-min(375px) {
                    font-size: 16px;
                }

                @include media-gt-tablet {
                    font-size: 18px;
                }
            }
        }

        .sheet-image {
            display: none;
            transition: transform 0.25s linear;

            &.scale-up {
                @include media-gt-tablet {
                    transform: scale(1);
                }
            }

            @include media-gt-tablet {
                display: block;
                position: absolute;
                right: 5%;
                bottom: 0;
                max-width: 50%;
                height: 90%;
                transform: scale(0);
            }
        }

        .neo-contents-footer {
        }
    }

    .neo-modal-regular,
    .neo-modal-image {
        height: 85vh;
        width: 95.5vw;
        position: relative;
        background-size: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background: black no-repeat center;

        @include media-gt-tablet {
            height: 95vh;
            width: 97.5vw;
            position: relative;
            border-radius: 8px;
        }

        .overlay-content {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 1;
            border-radius: 8px;

            &.darker {
                @include media-phone {
                    background-color: rgba(0, 0, 0, 0.45);
                }
            }
        }

        .neo-modal-contents {
            color: white;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 70px 30px 50px 30px;
            white-space: break-spaces;
            overflow-y: auto;
            // padding: 0 0 180px 70px;
            &::-webkit-scrollbar {
                display: none;
            }

            @include media-phone {
                width: 100% !important;
            }

            &.image-modal-small-text {
                padding: 80px 24px 35px;

                @include media-gt-tablet {
                    padding: 0 0 180px 70px;
                }
            }

            @include media-gt-tablet {
                justify-content: flex-end;
                padding: 0 0 50px 70px;
                width: 450px;
            }
        }

        img.lootverse {
            position: absolute;
            right: 21px;
            bottom: 14px;
            height: 50px;

            @include media-custom-min(300px) {
                right: 12px;
                height: 80px;
            }

            @include media-gt-tablet {
                right: 75px;
                bottom: 100px;
                height: 100px;
            }
        }

        .neo-modal-title {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.25;
            margin-bottom: 12px;

            @include media-custom-min(380px) {
                font-size: 26px;
            }

            @include media-gt-tablet {
                font-size: 36px;
                margin-bottom: 30px;
            }
        }

        .neo-modal-description {
            font-size: 15px;

            @include media-custom-min(375px) {
                font-size: 16px;
            }

            @include media-gt-tablet {
                font-size: 18px;
            }
        }

        .neo-contents-footer {
            position: absolute;
            bottom: 40px;
        }
    }

    .neo-modal-image {
        .neo-modal-contents {
            @include media-gt-tablet {
                width: 550px;
            }
        }
    }

    .neo-pwa-prompt {
        height: 500px;
        width: 500px;
    }
}

.newsletter-success {
    display: flex;
    gap: 15px;
    margin-top: 40px;

    .check-icon {
        font-size: 44px;
    }

    .title {
        font-size: 17px;
        margin: 0;
        color: white;
    }

    .text {
        font-size: 13px;
        font-weight: 400;
        margin: 0;
        color: var(--gray-50);
    }
}

.neo-contents-footer {
    color: white;
    padding-top: 20px;

    @include media-custom-min(300px) {
        padding: 0;
        position: absolute;
        bottom: 20px;
    }

    @include media-custom-min(400px) {
        padding: 0;
        position: absolute;
        bottom: 80px;
    }

    @include media-gt-tablet {
        bottom: 100px;
    }

    ul li {
        display: inline-block;
        padding-right: 10px;
        font-size: 16px;

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.neo-modal-node-enter {
    opacity: 0;

    .neo-modal-body {
        transform: translateY(100%);
        @include media-gt-tablet {
            transform: scale(0.96);
        }
    }
}

.neo-modal-node-enter-active {
    opacity: 1;
    transition: opacity 50ms;

    .neo-modal-body {
        transform: translateY(0);
        transition: transform 350ms linear;
        @include media-gt-tablet {
            transform: scale(1);
        }
    }
}

.neo-modal-node-exit {
    opacity: 1;

    .neo-modal-body {
        transform: translateY(0);
        @include media-gt-tablet {
            transform: scale(1);
        }
    }
}

.neo-modal-node-exit-active {
    opacity: 0;
    transition: opacity 80ms;

    .neo-modal-body {
        transform: translateY(100%);
        @include media-gt-tablet {
            transform: scale(0.96);
        }
        transition: transform 350ms linear;
    }
}
