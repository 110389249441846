.header {
    .header-tile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
    }
    .title {
        font-size: 16px;
        color: var(--gray-800);
        font-weight: 300;
    }
    .subtitle {
        font-size: 14px;
        color: var(--gray-500);
        margin-top: 10px;
    }
}

.chart-title {
    font-size: 14px;
    color: var(--gray-500);
    position: absolute;
    left: 25px;
}
