.sector-memory-display-card {
    cursor: pointer;
    padding: 16px 12px;
    border-radius: 12px;
    // align-items: center;
    .with-checkbox {
        display: grid;
        grid-template-columns: 25px auto;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    .sector-title {
        font-size: 15px;
        font-weight: 500;
    }

    .sector-description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .sector-card-footer {
        .rating-icon {
            color: #f1881e;
            font-size: 16px;
        }
    }
}
