.invitees-container {
    height: 100%;
    background-color: white;
    .invitees-card {
        display: grid;
        grid-template-columns: 50% 200px auto;
        padding: 12px 12px;
        border-bottom: 1px solid var(--gray-100);
        .user-info {
            display: flex;
            gap: 15px;
            img {
                height: 35px;
                width: 35px;
                border-radius: 100%;
            }
            .info {
                .name {
                    font-size: 14px;
                    color: var(--gray-900);
                    margin: 0;
                }
                .email {
                    font-size: 12px;
                    color: var(--gray-600);
                    margin: 0;
                }
            }
        }
        .actions {
            display: flex;
            justify-content: flex-end;
        }
    }
}
