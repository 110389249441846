$header-height: 55px;
.memories-library-container {
    height: 100%;
    background-color: white;
    border-radius: 8px;

    .memories-library-header {
        height: $header-height;

        .subtitle {
            margin-top: -1px !important;
        }

        input {
            height: 45px;
            border-radius: 8px;
        }
    }
}

.form-subtitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 7px;
}

.sector-memory-card {
    cursor: pointer;
    display: grid;
    grid-template-columns: 25px auto;
    padding: 16px 12px;
    border-radius: 12px;
    // align-items: center;
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    .sector-title {
        font-size: 15px;
        font-weight: 500;
    }

    .sector-description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .sector-card-footer {
        .rating-icon {
            color: #f1881e;
            font-size: 16px;
        }
    }
}

.sector-memory-card-search-input-group {
    position: relative;
    height: 45px;
    border-radius: 6px;
    border: 1px solid var(--gray-100);
    input {
        height: 100%;
        padding: 0 14px;
        width: 100%;
        outline: none;
        border: none;
        font-size: 14px;
        &:disabled {
            background-color: var(--gray-50);
        }
    }
    .loader {
        position: absolute;
        top: 2px;
        bottom: 2px;
        right: 2px;
        // background-color: white;
        border-radius: 6px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
