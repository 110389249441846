$header-height: 167px;
.prompt-library-container {
    height: 100%;
    background-color: white;
    border-radius: 8px;
    .prompt-header {
        height: $header-height;
        .subtitle {
            margin-top: -1px !important;
        }
        input {
            height: 45px;
            border-radius: 8px;
        }
    }

    .search-input-group {
        position: relative;
        height: 45px;
        border-radius: 6px;
        border: 1px solid var(--gray-100);
        input {
            height: 100%;
            padding: 0 14px;
            width: 100%;
            outline: none;
            border: none;
            font-size: 14px;
            &:disabled {
                background-color: var(--gray-50);
            }
        }
        .loader {
            position: absolute;
            top: 2px;
            bottom: 2px;
            right: 2px;
            // background-color: white;
            border-radius: 6px;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .arin-react-select__value-container,
    .arin-react-select__input-container,
    .arin-react-select__input,
    .arin-react-select__value-container--has-value {
        height: 45px !important;
        grid-template-rows: 45px;
        padding-top: 0 !important;
    }
    .arin-react-select__indicators {
        height: 45px;
    }
    .arin-react-select__placeholder,
    .arin-react-select__single-value {
        font-size: 14px;
    }
    .prompt-body {
        height: calc(100% - #{$header-height});
        overflow-y: auto;
    }
}

.prompt-library-card {
    background-color: var(--gray-50);
    border-radius: 8px;
    padding: 10px 12px;
    margin-bottom: 12px;
    border: 1px solid var(--gray-100);
    &:hover {
        background-color: var(--gray-100);
    }
    .prompt-library-card-title {
        display: grid;
        grid-template-columns: auto 105px;
        align-items: baseline;
        .title-text {
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            font-weight: bold;
            font-size: 14px;
            text-overflow: ellipsis;
        }
        .action {
            button {
                font-size: 13px;
                font-weight: 500;
                display: flex;
                align-items: center;
                padding: 3px 4px;
                border-radius: 6px;
                color: var(--blue-600);
                &:hover {
                    background-color: rgba(0, 0, 0, 0.05);
                }
                .icon {
                    font-size: 18px;
                }
            }
        }
    }
    .prompt-category-text {
        margin-top: -6px;
        font-size: 12px;
        margin-bottom: 4px;
    }
    .prompt-library-card-text {
        font-size: 13px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 190px;

    img {
        height: 55px;
        margin: auto;
    }
}
