.persona-list-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--gray-100);
    border-radius: 8px;
    cursor: pointer;
    &.active {
        border-color: var(--blue-500);
        background: var(--blue-50);

        .clone-text {
            color: var(--blue-700);
        }
    }
    .profile-image {
        height: 30px;
        width: 30px;
        border-radius: 100%;
    }
    .clone-text {
        font-size: 14px;
        font-weight: 500;
    }
    .clone-actions {
    }
}
