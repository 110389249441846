.drag-container {
    border: 1px solid #dddddd;
    width: 100%;
    min-height: 40px;
    border-radius: 8px;
    height: auto;
}

.drag-span {
    border: 1px solid #fefefe;
    display: inline-block;
    // height: 24px;
    // line-height: 18px;
    font-size: 18px;
    min-width: 4px;
    margin-right: 6px;
    margin-bottom: 4px;
    &.column {
        background-color: lighten(#2196f3, 40%);
        color: #04477c;
        border: 1px solid #dddddd;
        cursor: pointer;
        padding: 2px 4px;
        border-radius: 4px;
    }
}

.chip {
    display: inline-block;
    font-size: 15px;
    min-width: 4px;
    background-color: lighten(#2196f3, 40%);
    color: #04477c;
    border: 1px solid #dddddd;
    cursor: pointer;
    padding: 4px 6px;
    border-radius: 4px;
    margin-bottom: 8px;
}
